import * as Sentry from '@sentry/nextjs'

export type LocalStorageKey = string

export type LocalStorageValue =
  | string
  | number
  | boolean
  | { [key: string]: string | number | boolean }

export function saveLocalStorageData<T extends LocalStorageValue>(
  key: LocalStorageKey,
  value: T,
): boolean {
  try {
    if (typeof window !== 'undefined') {
      localStorage.setItem(key, JSON.stringify(value))
      return true
    } else {
      return false
    }
  } catch (error) {
    console.error('Error saving data to localStorage:', error)
    Sentry.captureException(error)
    return false
  }
}

export function fetchLocalStorageData<T extends LocalStorageValue>(
  key: LocalStorageKey,
): T | null {
  try {
    if (typeof window !== 'undefined') {
      const data = localStorage.getItem(key)
      return data ? (JSON.parse(data) as T) : null
    } else {
      return null
    }
  } catch (error) {
    console.error('Error fetching data from localStorage:', error)
    Sentry.captureException(error)
    return null
  }
}

export function removeLocalStorageData(key: LocalStorageKey): boolean {
  try {
    if (typeof window !== 'undefined') {
      localStorage.removeItem(key)
      return true
    } else {
      return false
    }
  } catch (error) {
    console.error('Error removing data from localStorage:', error)
    Sentry.captureException(error)
    return false
  }
}
