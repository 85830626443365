/* @TODO:
  We noticed the identify event was firing twice in Dev Mode, even though it was placed in a useEffect with an empty dependency array.
  This issue occurs because, starting with Next.js 13.4, strictMode is enabled by default. While disabling Strict Mode
  can improve compatibility with certain libraries and reduce console noise, it also has some drawbacks. By disabling this mode,
  we lose the benefits of additional checks and warnings that help identify potential issues in the application code.
  https://nextjs.org/docs/pages/api-reference/next-config-js/reactStrictMode
  https://react.dev/learn/you-might-not-need-an-effect#initializing-the-application
*/

'use client'

import { useEffect, useRef, useState } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'
import {
  clientTrackPageView,
  identifyUser,
  track,
  trackConsentEvent,
} from '@/lib/hightouch/events'
import {
  saveLocalStorageData,
  fetchLocalStorageData,
} from '@/utils/local-storage'
import { isRouteBlocked } from '@/utils/route-blocklist'
import * as Sentry from '@sentry/nextjs'
import { canTrack, getKetchConsent, KetchConsent } from '@/lib/ketch'

interface URLComponents {
  pathname: string
  search: string
  hash: string
}

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    ketch: any
  }
}

const CONSENT_HASH_KEY = 'ketch_consent_hash'

const generateConsentHash = (consent: KetchConsent): string =>
  btoa(JSON.stringify(consent)).replace(/=/g, '')

const saveConsentHashToStorage = (hash: string) => {
  localStorage.setItem(CONSENT_HASH_KEY, hash)
}

const getConsentHashFromStorage = (): string | null => {
  return localStorage.getItem(CONSENT_HASH_KEY)
}

export function HightouchController() {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const storedLeadId = fetchLocalStorageData<string>('lead_id')
  const [leadId, setLeadId] = useState<string | null>(storedLeadId)
  const hasUserInitialized = useRef(false)
  const [previousURL, setPreviousURL] = useState<URLComponents>({
    pathname: '',
    search: '',
    hash: '',
  })
  const [consentState, setConsentState] = useState<KetchConsent>({
    analytics: false,
    personalization: false,
    essential_services: true,
    product_enhancement: false,
  })
  const [hasPageEventFired, setHasPageEventFired] = useState(false)

  const initializeUser = async () => {
    if (storedLeadId || hasUserInitialized.current || !canTrack(consentState)) {
      return
    }

    try {
      const newLeadId = await identifyUser()
      saveLocalStorageData('lead_id', newLeadId)
      setLeadId(newLeadId)
      hasUserInitialized.current = true
    } catch (err) {
      console.error('ketch:: Error in user initialization:', err)
      Sentry.captureException(err)
    }
  }

  const getCurrentURL = (): URLComponents => ({
    pathname: pathname ?? '',
    search: searchParams.toString(),
    hash: window.location.hash,
  })

  const hasPathChanged = (currentURL: URLComponents, prevURL: URLComponents) =>
    currentURL.pathname !== prevURL.pathname
  const hasHashChanged = (currentURL: URLComponents, prevURL: URLComponents) =>
    currentURL.hash !== prevURL.hash

  const trackPageView = async (pathname: string, hash: string | null) => {
    if (leadId) {
      await clientTrackPageView(pathname, leadId, hash)
      setHasPageEventFired(true)
    }
  }

  const trackInternalNavigation = (
    pathname: string,
    search: string,
    hash: string,
  ) => {
    const url = `${pathname}${search}${hash}`
    track('Internal Navigation', { url })
  }

  const handleConsentUpdate = async (consentData: {
    purposes: KetchConsent
  }) => {
    const consentResponse = consentData.purposes
    const newConsentHash = generateConsentHash(consentResponse)
    const prevConsentHash = getConsentHashFromStorage()
    setConsentState(consentResponse)

    //compare against old consent hash to prevent duplicating consent events
    if (newConsentHash !== prevConsentHash && leadId) {
      saveConsentHashToStorage(newConsentHash)
      trackConsentEvent(leadId)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && window.ketch) {
      window.ketch(
        'on',
        'consent',
        (consentData: { purposes: KetchConsent }) => {
          handleConsentUpdate(consentData)
        },
      )
      getKetchConsent().then((consent) =>
        handleConsentUpdate({ purposes: consent }),
      )
    }
  }, [leadId])

  useEffect(() => {
    initializeUser()
  }, [consentState, storedLeadId])

  useEffect(() => {
    const trackNavigation = async (prevUrl: URLComponents) => {
      if (
        typeof window === 'undefined' ||
        !leadId ||
        isRouteBlocked(pathname ?? '') ||
        !canTrack(consentState)
      ) {
        return
      }

      try {
        const currentURL = getCurrentURL()
        const pageChanged = hasPathChanged(currentURL, prevUrl)
        const hashChanged = hasHashChanged(currentURL, prevUrl)

        if (pageChanged || !hasPageEventFired) {
          await trackPageView(currentURL.pathname, currentURL.hash ?? '')
        } else if (hashChanged) {
          trackInternalNavigation(
            currentURL.pathname,
            currentURL.search,
            currentURL.hash,
          )
        }
      } catch (err: any) {
        console.error('ketch:: Error in navigation tracking:', err)
        Sentry.captureException(err)
      }
    }

    const currentURL = getCurrentURL()
    const localPrevUrl = previousURL
    setPreviousURL(currentURL)
    trackNavigation(localPrevUrl)
  }, [pathname, searchParams, consentState, leadId, hasPageEventFired])

  return null
}
