const blockedRoutes = ['/chat', '/dashboard']

export function isRouteBlocked(path: string): boolean {
  const normalizedPath = path.endsWith('/') ? path.slice(0, -1) : path // Remove trailing slash

  return blockedRoutes.some(
    (route) =>
      normalizedPath === route || normalizedPath.startsWith(`${route}/`),
  )
}
