'use client'
import { useAuth } from '@clerk/nextjs'
import { useEffect } from 'react'

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    gtag: any
  }
}

const GoogleAnalytics = () => {
  const { userId } = useAuth()

  useEffect(() => {
    // get query params
    const urlParams = new URLSearchParams(window.location.search)
    const new_user = urlParams.get('new_user')

    // is window defined
    if (typeof window === 'undefined') {
      return
    }

    if (new_user === 'true') {
      if (window.gtag && userId) {
        window.gtag('event', 'sign_up', {
          event_category: 'engagement',
          event_label: 'new_user',
          transaction_id: userId,
        })
        window.gtag('event', 'conversion', {
          send_to: 'AW-11458851219/hBVxCPuFpo0ZEJPjgNgq',
        })
      }
    }
  }, [userId])

  return null
}

export default GoogleAnalytics
